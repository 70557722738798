import React from "react";
import { Link } from "react-router-dom";
import shapes from "../../../assets/img/shapes.png";
import SectionTitle from "../../../components/SectionTitle";
import img1 from "../img/blog-1.png";
import img2 from "../img/blog-2.png";
import img3 from "../img/blog-3.png";
import img4 from "../img/blog-4.png";
import img5 from "../img/blog-5.png";
import img6 from "../img/blog-6.png";
import img7 from "../img/blog-7.png";



const data = [
	{
		id: "1",
		img: img1,
		title: "How Hey! JAG Can Help Your Restaurant Turn Surplus Food into Extra Revenue",
		subtext:
			"In today's fast-paced restaurant industry, food waste is a challenge that many establishments face, often leading to unnecessary losses. However, Jag Heroes, an innovative initiative by Hey! Jag, offers a groundbreaking solution",
		text: [
			"By connecting restaurants with surplus food to eager consumers through the Hey! Jag app, this platform transforms unsold food into extra revenue while promoting sustainability. With no commission fees and a user-friendly interface, Jag Heroes empowers restaurants to turn a negative price into a positive value, enhancing both their visibility and profitability. Dive into this revolutionary approach and discover how your restaurant can benefit from joining the ",
		],
	},
	{
		id: "2",
		img: img2,
		title: "Say Goodbye to Food Waste: Boost Your Restaurant's Profits with Jag Heroes",
		subtext:
			"In an era where sustainability and profitability go hand in hand, Jag Heroes presents a groundbreaking solution to the persistent issue of food waste in the restaurant industry. This innovative initiative by Hey! Jag empowers restaurant owners to transform leftover food into revenue, all while contributing to a greener planet.",
		listSubText: "By leveraging the Jag Heroes platform, you can sell surplus food through engaging stories on the Hey! Jag app, boosting your restaurant's visibility and income without any commission fees. Embrace this opportunity to turn unsold inventory into a positive value, ensuring that your business thrives while making a meaningful environmental impact.",
		list: [
			
		],
	},
	{
		id: "3",
		img: img3,
		title: "Empower Your Business: The Benefits of Joining the Jag Heroes Initiative",
		subtext:
			"Empower your business and embrace sustainability with the Jag Heroes Initiative, a groundbreaking program by Hey! Jag designed to tackle food waste and promote community well-being. By joining Jag Heroes, restaurants can connect surplus food with eager consumers through the innovative Hey! Jag app, transforming potential losses into increased revenue and enhanced visibility.",
		text: [
			"As a Hey! Jag Merchant partner, you can sell your surplus food without any commission fees, turning unsold items into positive value while contributing to a greener planet. Download the Hey Jag Merchant App today, and start sharing your stories to boost your business and make a lasting environmental impact.",
		],
	},
	{
		id: "4",
		img: img4,
		title: "Turn Unsold Surplus into Revenue: How Jag Heroes is Revolutionizing Food Waste Management",
		subtext:
			"In an era where sustainability is more crucial than ever, Jag Heroes by Hey! Jag the Food Delivery App offers a groundbreaking solution to food waste management. By connecting restaurants with surplus food to eager consumers, Jag Heroes transforms unsold inventory into newfound revenue while championing environmental responsibility.",
		text: [
			"This innovative initiative not only enhances restaurant visibility but also fosters a positive community impact. Join us in saying goodbye to food waste and hello to increased profitability and sustainability. Download the 'Hey JAG Merchant App' today and become a part of this revolutionary movement.",
		],
	},
	{
		id: "5",
		img: img5,
		title: "Jag Heroes: Empowering Restaurants to Boost Visibility and Revenue with Surplus Food",
		subtext:
			"In a world increasingly conscious of sustainability, Jag Heroes emerges as a groundbreaking initiative by Hey! Jag, the food delivery app without junk fees, aimed at empowering restaurants to transform food surplus into a profitable and impactful resource. Through the innovative Jag Heroes platform, restaurants can connect with consumers eager for great deals or even free meals, thus reducing food waste while boosting visibility and revenue.",
		text: [
			"By turning a negative price into a positive value, participating restaurants can say goodbye to unsold food waste and hello to increased earnings and community goodwill. With no commissions on unsold food for Hey! Jag Merchant partners, this initiative offers a seamless solution that aligns financial success with environmental responsibility. Download the 'Hey JAG Merchant App' today and join the movement towards a more sustainable future.",
		],
	},
	{
		id: "6",
		img: img6,
		title: "Hey! JAG: The Future of Food Delivery and Waste Reduction",
		subtext:
			"Hey! JAG is revolutionizing the food delivery industry by addressing a critical issue: food waste. Through Hey! Jag, our innovative app without junk fees, we connect restaurants with surplus food to consumers eager for great deals or free meals, fostering sustainability and community support.",
		text: [
			"By turning unsold food into increased revenue and visibility, Jag Heroes empowers restaurant owners to save on costs and make a positive environmental impact. Best of all, our service is free for Hey! JAG Merchant partners, with no commission on unsold food. Join us in transforming negative prices into positive value and be a part of this ground-breaking initiative.",
		],
	},
	
];
const BlogCard = () => {
	return (
		<>
			<div className="blog-section section-gap">
				<div className="container">
					{data?.map(
						({ id, img, title, subtext, text, list, listSubText }, i) => (
							<div className="blog-card position-relative" key={i}>
								<img
									src={shapes}
									className="shape odd"
									style={{ top: "105px" }}
									alt=""
								/>
								<div
									className={`row gy-5 align-items-center position-relative ${
										i % 2 === 0 ? "flex-row-reverse" : ""
									}`}
								>
									<div className="col-lg-6 text-lg-end">
										<img
											src={img}
											alt=""
											className="mw-100"
											style={{ borderRadius: "10px" }}
										/>
									</div>
									<div className="col-lg-6">
										<div className="content">
											<SectionTitle
												shapeColor
												title={title ? title : ""}
												subtext={subtext ? subtext : ""}
											/>
											{text && (
												<div
													className="mt-29"
													style={{ maxWidth: "580px" }}
												>
													{text?.map((item, j) => (
														<p
															className={
																j + 1 === text.length
																	? "mb-0"
																	: ""
															}
														>
															{item}
														</p>
													))}
												</div>
											)}
											{list && (
												<div
													className="mt-29"
													style={{ maxWidth: "580px" }}
												>
													<div className="mb-1">{listSubText}</div>
													<ul className="ps-2">
														{list?.map((item, j) => (
															<li className="mb-1">
																{j + 1}. {item}
															</li>
														))}
													</ul>
												</div>
											)}
												<Link
											to={`/blog/${id}`}
												className="cmn-btn mt-37"
											>
												<span>READ MORE</span>
											</Link>
										</div>
									</div>
								</div>
							</div>
						)
					)}
				</div>
			</div>
		</>
	);
};

export default BlogCard;
