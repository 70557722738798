import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/img/logo.png";

const Header = ({ appLink }) => {
	return (
		<header>
			<div className="container">
				<div className="header-wrapper">
					<Link to="#" className="logo">
						<img src={logo} alt="#" />
					</Link>
					<div className="toggle-btn-group me-auto">
						<NavLink to="/">
							<span>User</span>
						</NavLink>
						<NavLink to="/restaurant">
							<span>Restaurant</span>
						</NavLink>	
					</div>
					
					<Link className="cmn-btn d-xl-none" to={appLink} target="_blank">
						<span>DOWNLOAD THE APP</span>
					</Link>
					
					


					<button
						type="button"
						className="cmn-btn d-none d-xl-block"
						data-bs-toggle="modal"
						data-bs-target="#register-modal"
					>
						<span>DOWNLOAD THE APP</span>
					</button>
				</div>
			</div>
		</header>
	);
};

export default Header;
