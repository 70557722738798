import React from "react";
import { Link } from "react-router-dom";
import user_mobile_screenshot from "../assets/img/about/app-screenshot.png";
import user_desk_image from "../assets/img/about/qr-code.png";
import img from "../assets/img/about/user-about.png";
import PopupModal from "./PopupModal";
const AboutUser = ({ appLink }) => {
	return (
		<>
			<section className="about-section">
				<div className="container">
					<div className="row gy-5 align-items-center">
						<div className="col-lg-6">
							<img
								src={img}
								className="w-100"
								style={{ maxWidth: "527px" }}
								alt=""
							/>
						</div>
						<div className="col-lg-6">
							<div
								className="about-content"
								style={{ maxWidth: "590px" }}
							>
								<h2 className="title">
									Feeding Change, <br /> One Heroic Meal At a Time:
								</h2>
								<p className="pt-2">
									JAG Heroes is an initiative by Hey! JAG that unites
									our community to create a positive impact on the
									planet. By rescuing unsold food, preventing waste and
									embracing sustainability, we are shaping a brighter
									future. Join us in our mission to make a difference.
									Together, we're heroes for the planet.
								</p>
								<div className="pt-3">
									<button
										type="button"
										className="cmn-btn download-btn d-none d-xl-block"
										data-bs-toggle="modal"
										data-bs-target="#register-modal"
										style={{ width: "100%", maxWidth: "254px" }}
									>
										<span>Download the app</span>
									</button>

									<Link
										className="cmn-btn download-btn d-xl-none"
										to={appLink}
										target="_blank"
										style={{ width: "100%", maxWidth: "254px" }}
									>
										<span>DOWNLOAD THE APP</span>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<PopupModal
				deskImage={user_desk_image}
				mobileImage={user_mobile_screenshot}
			/>
		</>
	);
};

export default AboutUser;
