import React from "react";

const Banner = ({ img, title, children }) => {
	return (
		<section
			className="banner-section"
			style={{ background: `url(${img}) no-repeat center center / cover` }}
		>
			<div className="container">
				<div className="banner-content">
					<h1 className="title">{title}</h1>
					{children}
				</div>
			</div>
		</section>
	);
};

export default Banner;
