import React from "react";
import bg1 from "../assets/img/feature/feature-bg-1.png";
import bg2 from "../assets/img/feature/feature-bg-2.png";
const Feature = ({ shapeReverse, data }) => {
	return (
		<section
			className="feature-section"
			style={{
				background: `url(${
					shapeReverse ? bg2 : bg1
				}) no-repeat center center / 100% 100%`,
			}}
		>
			<div className="feature-section-inner">
				<div className="container">
					<div className="row g-4 justify-content-center">
						{data?.map(({ img, title, text }, i) => (
							<div className="col-sm-6 col-lg-4" key={i}>
								<div className="feature-item">
									<img src={img} alt="" />
									<h3 className="subtitle">{title}</h3>
									<p>{text}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Feature;
