import React, { useState } from "react";
import { FaqClose, FaqOpen } from "./Icon";

const Faqs = ({ img, data, dir }) => {
	return (
		<>
			<section className="faqs-section">
				<div className="container">
					<div
						className={`faq-wrapper ${
							dir === "reverse" ? "reverse" : ""
						}`}
					>
						<div className="faqs-area">
							<h2 className="faq-title">What to know</h2>
							<FaqItems id="faqs-items" data={data} />
						</div>
						<div className="img-area">
							<img src={img} alt="" />
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

const FaqItems = ({ data, id }) => {
	const [open, setOpen] = useState(null);
	return (
		<div className="accordion" id={id}>
			{data?.map(({ title, text, linkText, link }, i) => (
				<div className="accordion-item" key={i}>
					<h2 className="accordion-header">
						<button
							className={`accordion-button  ${
								open === i ? "active" : ""
							}`}
							type="button"
							data-bs-toggle="collapse"
							data-bs-target={`#accordion-${id}-${i}`}
							aria-expanded="false"
							onClick={() => (open == i ? setOpen(null) : setOpen(i))}
						>
							<span className="me-2">{title} </span>
							<span className="plus ms-auto">
								<FaqOpen />
							</span>
							<span className="minus ms-auto">
								<FaqClose />
							</span>
						</button>
					</h2>
					<div
						id={`accordion-${id}-${i}`}
						className={`accordion-collapse collapse ${
							i === "0" ? "show" : ""
						}`}
						data-bs-parent={`#${id}`}
					>
						<div className="accordion-body">
							{text && (
								<p>
									{text}{" "}
									<a href={link} className="text-base">
										{linkText}
									</a>{" "}
								</p>
							)}
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default Faqs;
