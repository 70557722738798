import React from "react";
import SectionTitle from "../../../components/SectionTitle";
import img from "../img/subscribe-bg.png";
import logo from "../../../assets/img/logo.png";
import { Link, NavLink } from "react-router-dom";

// Header component
const Header = ({ appLink }) => {
	return (
		<header>
			<div className="container">
				<div className="header-wrapper">
					<Link to="#" className="logo">
						<img src={logo} alt="#" />
					</Link>
					<div className="toggle-btn-group me-auto">
						<NavLink to="/">
							<span>User</span>
						</NavLink>
						<NavLink to="/restaurant">
							<span>Restaurant</span>
						</NavLink>
						
					</div>
					
					<Link className="cmn-btn d-xl-none" to={appLink} target="_blank">
						<span>DOWNLOAD THE APP</span>
					</Link>
					<button
						type="button"
						className="cmn-btn d-none d-xl-block"
						data-bs-toggle="modal"
						data-bs-target="#register-modal"
					>
						<span>DOWNLOAD THE APP</span>
					</button>
				</div>
			</div>
		</header>
	);
};
const Subscribe = () => {
	const handleSubmit = (e) => {
		e.preventDefault();
	};
	return (
		<>
		
			<Header appLink="https://example.com/download" />

			<div
				className="subscribe-section"
				style={{
					background: `url(${img}) no-repeat center center / cover`,
				}}
			>
				<div className="container">
					<div className="subscribe-content">
						<div className="content">
							<SectionTitle
								centerAlign
								shapeColor
								title="Follow us"
								className="mb-3"
							/>
							<p className="text-center mb-4 pb-2">
								Are you interested in our activities? <br /> Subscribe
								to our newsletter to not miss anything!
							</p>
							<form onSubmit={handleSubmit}>
								<label className="form-label">Email Address</label>
								<input
									type="text"
									className="form-control mb-4 mb-md-5"
								/>
								<button
									className="cmn-btn w-100 text-uppercase"
									type="submit"
								>
									<span>Subscribe</span>
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Subscribe;
