import React from "react";
import { EffectCreative, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { useRef } from "react";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";

import { useCallback } from "react";
import { Link } from "react-router-dom";
import { NextSlideIcon, PrevSlideIcon } from "./Icon";

const HowItWorks = ({ btnText, appLink, data }) => {
	const ref = useRef();

	const prevSlide = useCallback(() => {
		if (!ref.current) return;
		ref.current.swiper.slidePrev();
	}, []);

	const nextSlide = useCallback(() => {
		if (!ref.current) return;
		ref.current.swiper.slideNext();
	}, []);

	return (
		<section className="how-it-works">
			<div className="container">
				<Swiper
					modules={[Pagination, EffectCreative]}
					spaceBetween={50}
					slidesPerView={1}
					pagination={{
						clickable: true,
					}}
					effect="creative"
					ref={ref}
					noSwiping="true"
					noSwipingClass="swiper-slide"
				>
					{data?.map(({ img, title }, i) => (
						<SwiperSlide key={i}>
							<div className="how-item">
								<div className="img">
									<img src={img} alt="" />
								</div>
								<div className="cont">
									<h2 className="title text-base">How it works</h2>
									<p>{title}</p>
									<button
										type="button"
										data-bs-toggle="modal"
										data-bs-target="#register-modal"
										className="cmn-btn download-btn d-none d-xl-block"
										style={{ width: "100%", maxWidth: "254px" }}
									>
										<span>
											{btnText ? btnText : "register your buisness"}
										</span>
									</button>
									<Link
										className="cmn-btn d-xl-none"
										to={appLink}
										target="_blank"
										style={{ width: "100%", maxWidth: "254px" }}
									>
										<span>register your buisness</span>
									</Link>
								</div>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
				<div className="swiper-action-buttons">
					<button
						className="no-gutter"
						type="button"
						onClick={() => prevSlide()}
					>
						<PrevSlideIcon />
					</button>

					<button
						className="no-gutter"
						type="button"
						onClick={() => nextSlide()}
					>
						<NextSlideIcon />
					</button>
				</div>
			</div>
		</section>
	);
};

export default HowItWorks;
