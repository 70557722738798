import React from "react";
import img from "../assets/img/about/restaurant-about.png";
import store_desk_image from "../assets/img/about/restaurant-qr-code.png";
import store_mobile_screenshot from "../assets/img/about/store-mobile-screenshot.png";
import PopupModal from "./PopupModal";
const AboutRestaurant = () => {
	return (
		<>
			<section className="about-section about-section-2">
				<div className="container">
					<div className="row gy-5 align-items-center">
						<div className="col-lg-6">
							<img
								src={img}
								className="w-100"
								style={{ maxWidth: "605px" }}
								alt=""
							/>
						</div>
						<div className="col-lg-6">
							<div className="about-content ms-lg-auto">
								<h2 className="title">
									Turn a Negative Price into <br /> a Positive Value
								</h2>
								<p>
									Say goodbye to unsold food waste and hello to
									increased revenue and visibility. Sell your surplus
									food through our platform and save on costs while
									making a positive environmental impact.
								</p>
								<p>
									JAG Heroes is free for Hey! JAG Merchant partners.{" "}
									<br className="d-none d-xl-inline" /> We do not take
									any commission on unsold food you sell.
								</p>
								<div className="pt-3">
									<button
										type="button"
										className="cmn-btn download-btn"
										data-bs-toggle="modal"
										data-bs-target="#register-modal"
										style={{ width: "100%", maxWidth: "315px" }}
									>
										<span>register your buisness</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<PopupModal
				deskImage={store_desk_image}
				mobileImage={store_mobile_screenshot}
			/>
		</>
	);
};

export default AboutRestaurant;
