import React from "react";
import { ClearIcon } from "./Icon";
const PopupModal = ({ mobileImage, deskImage }) => {
	return (
		<>
			<div
				className="modal fade"
				id="register-modal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div
					className="modal-dialog modal-dialog-centered"
					style={{
						maxWidth: "843px",
					}}
				>
					<div className="modal-content border-0">
						<div className="modal-body p-0">
							<div className={`popup active`}>
								<div className="popup-inner">
									<button
										className="no-gutter close-btn"
										type="button"
										data-bs-dismiss="modal"
									>
										<ClearIcon />
									</button>
									<div className="qr-item d-none">
										<div className="left">
											<h2 className="title">
												Download on: <br /> App Store & <br />{" "}
												Google Play{" "}
											</h2>
										</div>
										<div className="right px-3">
											<img
												src={deskImage}
												className="mw-100"
												alt=""
											/>
										</div>
										<div className="w-100">
											By scanning this QR Code
										</div>
									</div>
									<div className="d-sm-none w-100 img">
										<img src={mobileImage} className="w-100" alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PopupModal;
