import React from "react";

const NearDeals = () => {
	return (
		<section className="near-deals-section">
			<div className="container">
				<div className="near-deal-content">
					<h2 className="title">Discover deals near you</h2>
					<p style={{ fontWeight: "500" }}>
						Find Meal Bags available nearby by checking out the JAG Heroes
						tab in the Hey! JAG app. Explore a variety of food offered by
						restaurants around you at a fraction of its original price. 
					</p>
				</div>
				<div style={{ maxWidth: "1170px" }} className="mx-auto">
					<div className="row g-4">
						<div className="col-sm-4">
							<div className="count-item">
								<h2 className="count">14</h2>
								<div>Bakeries</div>
							</div>
						</div>
						<div className="col-sm-4">
							<div className="count-item">
								<h2 className="count">92</h2>
								<div>Restaurants</div>
							</div>
						</div>
						<div className="col-sm-4">
							<div className="count-item">
								<h2 className="count">521</h2>
								<div>Meal Bags Saved</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default NearDeals;
