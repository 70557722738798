import React from "react";
import { Link } from "react-router-dom";
import banner_bg from "../assets/img/restaurant-banner.png";
import AboutRestaurant from "../components/AboutRestaurant";
import Banner from "../components/Banner";
import Layout from "../components/Layout";

import { useContext } from "react";
import { MyContext } from "../App";
import faqImg from "../assets/img/faq-2.png";
import feature1 from "../assets/img/feature/4.svg";
import feature2 from "../assets/img/feature/5.svg";
import feature3 from "../assets/img/feature/6.svg";
import Faqs from "../components/Faqs";
import Feature from "../components/Feature";
import HowItWorks from "../components/HowItWorks";
import WeAreHere from "../components/WeAreHere";

import img1 from "../assets/img/how-to/1.png";
import img2 from "../assets/img/how-to/2.png";
import img3 from "../assets/img/how-to/3.png";

const RestaurantHome = () => {
	const { apple } = useContext(MyContext);
	return (
		<>
			<Layout
				appLink={
					apple ? restaurant_appstore_link : restaurant_playstore_link
				}
			>
				<Banner img={banner_bg} title="Unlocking Revenue in Unsold Food">
					<div className="d-flex flex-wrap gap-3 justify-content-center">
						<Link
							to={
								apple
									? restaurant_appstore_link
									: restaurant_playstore_link
							}
							className="cmn-btn text-uppercase banner-btn d-xl-none"
							target="_blank"
							style={{ width: "100%", maxWidth: "315px" }}
						>
							<span>register your buisness</span>
						</Link>
						<Link
							to="#"
							className="cmn-btn text-uppercase banner-btn d-none d-xl-block"
							data-bs-toggle="modal"
							data-bs-target="#register-modal"
							style={{ width: "100%", maxWidth: "315px" }}
						>
							<span>register your buisness</span>
						</Link>
					</div>
				</Banner>
				<AboutRestaurant />
				<Feature data={features} shapeReverse />
				<HowItWorks
					data={how_it_works}
					appLink={
						apple ? restaurant_appstore_link : restaurant_playstore_link
					}
				/>
				<WeAreHere
					appLink={
						apple ? restaurant_appstore_link : restaurant_playstore_link
					}
				/>
				<Faqs dir="reverse" img={faqImg} data={faqs} />
			</Layout>
		</>
	);
};

export const restaurant_playstore_link =
	"https://play.google.com/store/apps/details?id=com.heyjageats.restaurant";
export const restaurant_appstore_link =
	"https://apps.apple.com/us/app/hey-jag-merchant/id6447587553";
const features = [
	{
		img: feature1,
		title: "N*1",
		text: "The foodservice industry is the largest generator of food waste in the US.",
	},
	{
		img: feature2,
		title: "10 Pounds",
		text: "The average restaurant wastes about 10 pounds of unsold food per day.",
	},
	{
		img: feature3,
		title: "$1.60 per pounds ",
		text: "The average cost of unsold food waste to restaurants in the US. Over the course of a year, this adds up to $5,760.",
	},
];
const faqs = [
	{
		title: "When do I get paid?",
		text: "You'll receive your earnings at the end of each week, deposited deirecly into the bank you've provided on Hey! JAG Merchant.",
	},
	{
		title: "How much does it cost?",
		text: "JAG Heroes is 100% free for Hey! JAG Merchant partners, we do not take any fees or commissions on Meal Bags you sold.",
	},
	{
		title: "How to become a partner?",
		text: "To use JAG Heroes you need first to be a Hey! JAG Merchant partner. Download the Hey! JAG Merchant app, register your restaurant and once you are approved, you can start selling surplus food via JAG Heroes.To use JAG Heroes, you need to first register as a Hey! JAG Merchant partner.",
	},
	{
		title: "What is Hey! JAG Merchant?",
		text: "Hey! JAG is an on-demand multi-service app built on Web3. JAG Heroes is a standout initiative within Hey! JAG. It enables our restaurant partners on the platform to cut food waste by offering surplus meals to customers at reduced prices. Learn more about ",
		link: "#",
		linkText: "Hey!JAG",
	},
	{
		title: "How does it benefit my business?",
		text: "JAG Heroes provide you with an additional source of income by selling excess food that would have otherwise gone to waste. It also showcases your commitment to sustainability and helps recover some of the costs associated with overproduction or slow business periods.",
	},
];

const how_it_works = [
	{
		img: img1,
		title: "Download the Hey! JAG Merchant app and become a restaurant partner by registering your business.",
	},
	{
		img: img2,
		title: "Once approved, your restaurant can begin selling surplus food through JAG Heroes.",
	},
	{
		img: img3,
		title: "In the JAG Heroes tab, add your surplus food. Users can now see your available Meal Bags via pick-up only.",
	},
	{
		img: img1,
		title: "Users come to your place, confirm the purchase within their app and collect the Meal Bag.",
	},
	{
		img: img2,
		title: "You've sold your surplus, cut your lost costs and helped the planet",
	},
];
export default RestaurantHome;
