import React from "react";
import { Link } from "react-router-dom";
import img from "../assets/img/register-your-business.png";
import { HeroesVector } from "./Icon";
const WeAreHere = ({ appLink }) => {
	return (
		<>
			<section className="we-are-here-section">
				<div className="container">
					<h2 className="we-are-here-title">
						We are <HeroesVector /> for the planet
					</h2>
					<div className="we-are-wrapper">
						<div className="cont">
							<h3 className="title text-base">
								100% Free, No Commission: Join JAG Heroes!
							</h3>
							<p>
								Transform your surplus food from being discarded to
								being saved and valued. JAG Heroes ensure that your
								culinary creations find appreciative palates, creating a
								meaningful impact by preventing waste.
							</p>
							<div className="pt-3">
								<button
									type="button"
									className="cmn-btn download-btn d-none d-xl-block"
									data-bs-toggle="modal"
									data-bs-target="#register-modal"
									style={{ width: "100%", maxWidth: "315px" }}
								>
									<span>register your buisness</span>
								</button>
								<Link
									className="cmn-btn d-xl-none"
									to={appLink}
									target="_blank"
									style={{ width: "100%", maxWidth: "315px" }}
								>
									<span>register your buisness</span>
								</Link>
							</div>
						</div>
						<div className="img">
							<img src={img} alt="" />
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default WeAreHere;
