import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ appLink, children }) => {
	return (
		<>
			<Header appLink={appLink} />
			{children}
			<Footer />
		</>
	);
};

export default Layout;
