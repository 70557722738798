import React from "react";
import banner_bg from "../assets/img/user-banner.png";
import Banner from "../components/Banner";
import Layout from "../components/Layout";

import { Link } from "react-router-dom";
import app_store from "../assets/img/apple.svg";
import play_store from "../assets/img/google.svg";
import AboutUser from "../components/AboutUser";
import Feature from "../components/Feature";

import feature1 from "../assets/img/feature/1.svg";
import feature2 from "../assets/img/feature/2.svg";
import feature3 from "../assets/img/feature/3.svg";
import Faqs from "../components/Faqs";

import img1 from "../assets/img/how-to/1.png";
import img2 from "../assets/img/how-to/2.png";
import img3 from "../assets/img/how-to/3.png";

import { useContext } from "react";
import { MyContext } from "../App";
import faqImg from "../assets/img/faq-1.png";
import HowItWorks from "../components/HowItWorks";
import NearDeals from "../components/NearDeals";
const UserHome = () => {
	const { apple } = useContext(MyContext);
	return (
		<>
			<Layout appLink={apple ? user_appstore_link : user_playstore_link}>
				<Banner img={banner_bg} title="Be a Hero, save the planet">
					<div className="d-flex flex-wrap gap-3 justify-content-center">
						<Link
							to={user_appstore_link}
							target="_blank"
							className="app-btn"
						>
							<img src={app_store} alt="" />
						</Link>
						<Link
							to={user_playstore_link}
							target="_blank"
							className="app-btn"
						>
							<img src={play_store} alt="" />
						</Link>
					</div>
				</Banner>
				<AboutUser
					appLink={apple ? user_appstore_link : user_playstore_link}
				/>
				<Feature data={features} />
				<HowItWorks
					data={how_it_works}
					btnText="Download The App"
					appLink={apple ? user_appstore_link : user_playstore_link}
				/>
				<NearDeals />
				<Faqs img={faqImg} data={faqs} />
			</Layout>
		</>
	);
};
export const user_playstore_link =
	"https://play.google.com/store/apps/details?id=com.heyjageats.customer";
export const user_appstore_link =
	"https://apps.apple.com/us/app/hey-jag/id6447595812";
const features = [
	{
		img: feature1,
		title: "Unlock Savings",
		text: "Enjoy significant cost savings by purchasing surplus restaurant food at a fraction of the regular price.",
	},
	{
		img: feature2,
		title: "Waste Reduction",
		text: "Contribute to reducing food waste and its environmental impact by rescuing surplus food.",
	},
	{
		img: feature3,
		title: "Champion Sustainability",
		text: "Support eco-friendly practices by choosing restaurants that prioritize waste reduction.",
	},
];
const faqs = [
	{
		title: "What is Hey! JAG",
		text: `Hey! JAG is an on-demand multi-service app built on Web3. JAG Heroes is a standout initiative within Hey! JAG. It enables our restaurant partners on the platform to cut food waste by offering surplus meals to customers at reduced prices. Learn more about `,
		link: "#",
		linkText: "Hey!JAG",
	},
	{
		title: "How to get a Meal Bag?",
		text: `Simply download the JAG Food app and go to the JAG Heroes tab to explore restaurants near you that offer Meal Bags. Reserve it and confirm your purchase, then go pick it up!`,
	},
	{
		title: "What type of food can I expect?",
		text: `Meal Bags are packed with food that matches with the restaurant's specific cuisine type. Each bag is unique, featuring items based on the restaurant's menu. You'll enjoy variety and surprises with every order.`,
	},
	{
		title: "What is the price of a Meal Bag?",
		text: `Indulge in meals at 80% of their regular prices, ensuring restaurant costs are covered. We don't burden restaurants with service fees. Partnering with us is cost-free, promoting waste reduction and delivering discounts to you.`,
	},
	{
		title: "Do I need to download Hey! JAG to get access to JAG Heroes?",
		text: `JAG Heroes is a service available through Hey! JAG. To be able to purchase Meal Bags you will need to download Hey! JAG and then head up to the JAG Heroes tab.`,
	},
];
const how_it_works = [
	{
		img: img1,
		title: "Download Hey! JAG app and search for Meal Bags in the JAG Heroes tab from restaurants near you.",
	},
	{
		img: img2,
		title: "Check restaurants near you that offer Meal Bags, reserve it and confirm your purchase. Head to the restaurant to collect.",
	},
	{
		img: img3,
		title: "Pick up your food to go and enjoy. You've rescued a meal from being wasted and made a positive impact on our planet!",
	},
];
export default UserHome;
