import React from "react";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import RestaurantHome from "./pages/RestaurantHome";
import UserHome from "./pages/UserHome";
import Blog from "./pages/blog/Blog";
import BlogSingle from "./pages/blog/BlogSingle";

export const MyContext = createContext("");

function App() {
	const [os, setOs] = useState("");

	const [apple, setApple] = useState(false);

	function detectOS() {
		const platform = navigator.platform;
		if (platform.indexOf("Win") !== -1) return "Windows";
		if (platform.indexOf("Mac") !== -1) return "Mac OS";
		if (platform.indexOf("Linux") !== -1) return "Linux";
		if (platform.indexOf("iPhone") !== -1) return "iOS";
		if (platform.indexOf("Android") !== -1) return "Android";
		if (platform.indexOf("iPad") !== -1) return "iPad";
		return "Unknown";
	}

	useEffect(() => {
		const detectedOs = detectOS();
		setOs(detectedOs);
		if (os) {
		}
	}, []);
	useEffect(() => {
		if (os == "Mac OS" || os == "iOS" || os == "iPad") {
			setApple(true);
		} else {
			setApple(false);
		}
	}, [os]);

	return (
		<>
			<BrowserRouter>
				<MyContext.Provider value={{ apple }}>
					<Routes>
						<Route path="/" element={<UserHome />} />
						<Route path="/restaurant" element={<RestaurantHome />} />
						<Route path="/blog" element={<Blog />} />
						<Route path="/blog/:id" element={<BlogSingle />} />
					</Routes>
				</MyContext.Provider>
			</BrowserRouter>
		</>
	);
}

export default App;
