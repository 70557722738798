import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import { Facebook, Instagram, LinkedinIn, Telegram, X, Youtube } from "./Icon";
const Footer = () => {
	return (
		<footer>
			<div className="container">
				<div className="footer-wrapper">
					<div className="footer-widget widget-about">
						<Link to="#" className="footer-logo">
							<img src={logo} alt="" />
						</Link>
						<p>
							The first on-demand multi-service web3 mobile app powered
							by its community that simplifies users daily life.
						</p>
					</div>
					<div className="footer-widget widget-social">
						<h5 className="title">Community</h5>
						<ul className="social-icons">
							{social?.map(({ icon, url }, i) => (
								<li key={i}>
									<Link to={url} target="_blank">
										{icon}
									</Link>
								</li>
							))}
						</ul>
					</div>
					<div className="footer-widget widget-links">
						<h5 className="title">About Us</h5>
						<ul className="links">
							<li>
								<Link
									to="https://www.heyjag.io/whitepaper.pdf"
									target="_blank"
								>
									Whitepaper
								</Link>
							</li>
							<li>
								<Link to="#">Contact us</Link>
							</li>
							<li>
								<Link to="https://www.heyjag.com/" target="_blank">
									Hey! JAG
								</Link>
							</li>
							<li>
								<Link to="https://www.heyjageats.com/" target="_blank">
									JAG Food 
								</Link>
							</li>
							<li>
								<Link to="https://www.heyjag.io/" target="_blank">
									JAG Token
								</Link>
							</li>
							<li>
								<Link to="https://www.simplevirtualkitchen.com/" target="_blank">
									Simple Virtual Kitchen
								</Link>
							</li>
							<li>
								<Link to="/blog">
									Blog
								</Link>
							</li>
						</ul>
					</div>
					<div className="footer-widget widget-links">
						<h5 className="title">Legal</h5>
						<ul className="links">
							<li>
								<Link to="#">Terms Of Service</Link>
							</li>
							<li>
								<Link to="#">Privacy Policy</Link>
							</li>
						</ul>
					</div>
					<div className="footer-widget widget-links">
						<h5 className="title">Contact Information</h5>
						<ul className="links">
						<div className="footer-widget widget-about">
								<p>350 N Federal Hwy,</p>
								<p>Boynton Beach, FL, 33435</p>
							</div>
							<div className="footer-widget widget-about">
								<p>contact@HeyJAG.com</p>
								<p>(561) 319-0974</p>
							</div>
						</ul>
					</div>
				</div>
				<div className="footer-bottom">
					Copyright &copy; 2024 Jag Heroes. All right reserved.
				</div>
			</div>
		</footer>
	);
};

const social = [
	{
		icon: <Facebook />,
		url: "https://www.facebook.com/HeyJag",
	},
	{
		icon: <Instagram />,
		url: "https://www.instagram.com/heyjag_/",
	},
	{
		icon: <LinkedinIn />,
		url: "https://www.linkedin.com/company/heyjag/",
	},
	{
		icon: <Youtube />,
		url: "https://www.youtube.com/channel/UCi65xBvmllHgZyWOXMV5LcA",
	},
	{
		icon: <Telegram />,
		url: "https://t.me/HeyJagOfficial",
	},
	{
		icon: <X />,
		url: "https://twitter.com/hey_jag",
	},
	// {
	// 	icon: <Discord />,
	// 	url: "https://discord.com/invite/D5JJaUfPwF",
	// },
];

export default Footer;
